// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#product-item_product__5hKR0{\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: space-around; \r\n    flex-wrap: wrap;\r\n    align-content: flex-start;\r\n    list-style-type: none;\r\n  }\r\n  .product-item_card__3f30t{\r\n    min-width: 300px;\r\n    min-height: 400px;\r\n    border: 2px solid #eee;\r\n    overflow: hidden;\r\n    padding: 10px;\r\n    box-shadow: 2px 8px 20px #0000007c;\r\n    margin: 10px;\r\n    transition: 0.5s linear;\r\n  }\r\n  .product-item_card__3f30t:hover{\r\n    box-shadow: none;\r\n  }\r\n  .product-item_card__3f30t img{\r\n    max-width: 300px;\r\n    width: 100%;\r\n    height: 100%;\r\n    max-height: 300px;\r\n    display: block;\r\n    object-fit: cover;\r\n  }\r\n  .product-item_card__3f30t h3{\r\n    text-transform: uppercase;\r\n    margin: 10px 0;\r\n  }\r\n  .product-item_card__3f30t h3 a{\r\n    text-decoration: none;\r\n    color: #333;\r\n  }\r\n  .product-item_card__3f30t h3 a:hover{\r\n    color: crimson;\r\n  }\r\n  .product-item_card__3f30t p{\r\n    margin: 10px 0;\r\n  }\r\n  .product-item_content__2YSyG span{\r\n    color: rgb(31, 152, 244);\r\n  }", ""]);
// Exports
exports.locals = {
	"product": "product-item_product__5hKR0",
	"card": "product-item_card__3f30t",
	"content": "product-item_content__2YSyG"
};
module.exports = exports;
