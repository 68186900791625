// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown_name__3EKL9{\r\n    font-size: 15px;\r\n    color: #000000;\r\n    text-decoration: none;\r\n    outline-style: none;\r\n}\r\n\r\n.dropdown_dropdown__2Z72V:hover .dropdown_name__3EKL9{\r\n    color: #ffffff;\r\n    cursor: pointer;\r\n    transition: 1s;\r\n    text-decoration: none;\r\n    outline-style: none;\r\n}\r\n\r\n.dropdown_dropdown__2Z72V{\r\n    position: absolute;\r\n    top: 80px;\r\n    width: 200px;\r\n    background-color: #ffffff;\r\n    transform: translateX(-45%);\r\n    border-radius: 8px;\r\n    padding: 1rem;\r\n    overflow: hidden;\r\n    box-shadow: 0 8px 16px rgba(0,0,0,.16);\r\n}\r\n\r\n.dropdown_dropdown__2Z72V:hover{\r\n    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);  */\r\n    background-color: #1C1B1B;\r\n    transition: 1s;\r\n}\r\n\r\n.dropdown_menu_item__4ZIje{\r\n    height: 50px;\r\n    display: flex;\r\n    align-items: center;\r\n    border-radius: 8px;\r\n    transition: ease-out;\r\n    padding: 0.5rem;\r\n    text-decoration: none;\r\n}\r\n\r\n.dropdown_menu_item__4ZIje:hover{\r\n    background-color: #111111;\r\n    text-decoration: none; \r\n    transition: 1s;\r\n}", ""]);
// Exports
exports.locals = {
	"name": "dropdown_name__3EKL9",
	"dropdown": "dropdown_dropdown__2Z72V",
	"menu_item": "dropdown_menu_item__4ZIje"
};
module.exports = exports;
