// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".search_results_ul__1CKqo{\r\n    position: absolute;\r\n    left: 180px;\r\n    background-color: white;\r\n    outline-style: none;\r\n    list-style: none;\r\n    margin-top: 20px;\r\n    padding-top: 10px;\r\n    padding-right: 150px;\r\n    padding-left: 20px;\r\n    padding-bottom: 10px;\r\n    border-radius: 10px;\r\n}\r\n\r\n.search_results_ul__1CKqo:hover{\r\n    cursor: pointer;\r\n}\r\n\r\n.search_none__2fsxO{\r\n    display: none;\r\n}", ""]);
// Exports
exports.locals = {
	"results_ul": "search_results_ul__1CKqo",
	"none": "search_none__2fsxO"
};
module.exports = exports;
