// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App_home__1GFf4,\n.App_categories__3owla,\n.App_products__2pwXq,\n.App_popular__SUM1j,\n.App_sign-up__fCL8A {\n  display: flex;\n  height: 90vh;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n}\n\n.App_categories__3owla {\n  background-image: url('/images/img-33.jpg');\n  background-position: center;\n  background-size: fill;\n  background-repeat: no-repeat;\n  color: #fff;\n}\n\n.App_h1__3-52t{\n  font-size: 100px;\n}\n\n.App_products__2pwXq {\n  background-image: url('/images/img-15.jpg');\n  background-position: center;\n  background-size: fill;\n  background-repeat: no-repeat;\n  color: #fff;\n}\n\n.App_popular__SUM1j {\n  background-image: url('/images/img-32.jpg');\n  background-position: center;\n  background-size: fill;\n  background-repeat: no-repeat;\n  color: #fff;\n}\n\n\n\n.App_sign-up__fCL8A {\n  background-image: url('/images/img-8.jpg');\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  color: #fff;\n  font-size: 100px;\n}\n", ""]);
// Exports
exports.locals = {
	"home": "App_home__1GFf4",
	"categories": "App_categories__3owla",
	"products": "App_products__2pwXq",
	"popular": "App_popular__SUM1j",
	"sign-up": "App_sign-up__fCL8A",
	"h1": "App_h1__3-52t"
};
module.exports = exports;
