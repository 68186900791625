import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDkUghwfLAiIkOB0iwkJnMQyq6DeJaxh1I",
    authDomain: "project-ws-d1676.firebaseapp.com",
    projectId: "project-ws-d1676",
    storageBucket: "project-ws-d1676.appspot.com",
    messagingSenderId: "37370850626",
    appId: "1:37370850626:web:cf10f270987bf101fd6cdb"
}

firebase.initializeApp(config);
export default firebase;