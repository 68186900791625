// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.login_id__3Bee3{\n    color: #fff;\n}\n\n\n.login_main_head__1dc9a{\n    height: 150px;\n    background: #FFF;\n   \n}\n\n.login_sidenav__21Cf4 { \n    height: 100%;\n    background-color: rgb(27, 26, 26);\n    overflow-x: hidden;\n    padding-top: 20px;\n}\n\n.login_error__3cZrj p{\n    color: red;\n    font-size: 13px;\n}\n\n\n.login_success__3cuA_ p{\n    color: rgba(0, 128, 0, 0.808);\n    font-size: 13px;\n}\n\n.login_success__3cuA_{\n    color: rgba(0, 128, 0, 0.808);\n    font-size: 14px;\n}\n\n.login_notfound__3ioP- p{\n    color: red;\n    font-size: 13px;\n}\n\n.login_notfound__3ioP-{\n    color: red;\n    font-size: 14px;\n}\n/* input[type=\"email\"]:invalid{\n    border-color: red;\n    color: red;\n} */\n\n\n.login_main__1Nr7y {\n    padding: 0px 10px;\n}\n\n@media screen and (max-height: 450px) {\n    .login_sidenav__21Cf4 {padding-top: 15px;}\n}\n\n@media screen and (max-width: 450px) {\n    .login_login-form__1a1MI{\n        margin-top: 10%;\n    }\n\n    .login_register-form__YJ9aP{\n        margin-top: 10%;\n    }\n}\n\n@media screen and (min-width: 768px){\n    .login_main__1Nr7y{\n        margin-left: 40%; \n    }\n\n    .login_sidenav__21Cf4{\n        width: 40%;\n        position: fixed;\n        z-index: 1;\n        top: 0;\n        left: 0;\n    }\n\n    .login_login_form__OleBG{\n        margin-top: 50%;\n    }\n\n    .login_register_form__2oFpR{\n        margin-top: 20%;\n    }\n}\n\n\n.login_login_main_text__3E-04{\n    margin-top: 20%;\n    padding: 60px;\n    color: #fff;\n}\n\n.login_login_main_text__3E-04 h2{\n    font-weight: 300;\n}\n\n.login_btn_black__12iXR{\n    background-color: rgb(27, 26, 26) !important;\n    color: #fff;\n    border-radius: 8px;\n    padding: 4px;\n    padding-left: 10px;\n    padding-right: 10px;\n}\n.login_btn_secondary__1wrdq{\n    margin-left: 3%;\n    border-radius: 8px;\n    padding: 4px;\n    padding-left: 10px;\n    padding-right: 10px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"id": "login_id__3Bee3",
	"main_head": "login_main_head__1dc9a",
	"sidenav": "login_sidenav__21Cf4",
	"error": "login_error__3cZrj",
	"success": "login_success__3cuA_",
	"notfound": "login_notfound__3ioP-",
	"main": "login_main__1Nr7y",
	"login-form": "login_login-form__1a1MI",
	"register-form": "login_register-form__YJ9aP",
	"login_form": "login_login_form__OleBG",
	"register_form": "login_register_form__2oFpR",
	"login_main_text": "login_login_main_text__3E-04",
	"btn_black": "login_btn_black__12iXR",
	"btn_secondary": "login_btn_secondary__1wrdq"
};
module.exports = exports;
